import { useEffect, useState } from "react";

export function useTimeoutTrigger(
  delay: number = 5000,
  callback?: () => void | undefined
) {
  const [state, setState] = useState(false);

  useEffect(() => {
    let timerId: NodeJS.Timeout | null = null;
    if (state) {
      timerId = setTimeout(() => {
        setState(false);
        callback?.();
      }, delay);
    }
    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [callback, delay, state]);

  return { state, setState };
}
